<template>
  <div class="wrapper">
    <div style="display: flex; width: 100%" class="mb-3">
      <div class="inner-btn d-md-block d-lg-block d-xl-none">
        <b-button
          v-if="loadingP != true"
          class="refresh"
          @click="() => this.$store.dispatch('apiListProject/getData')"
        >
          <img src="../../assets/icon/icon/rotate-cw.svg" alt="" />
        </b-button>
        <b-button class="refresh" disabled v-else>
          <img src="../../assets/icon/icon/rotate-cw.svg" alt="" />
        </b-button>
      </div>
      <div class="inner-btn d-md-block d-lg-block d-xl-none">
        <b-button
          v-if="
            (loadingP != true && role == 'manager') ||
            role == 'admin' ||
            role == 'supervisor'
          "
          class="project"
          @click="openModal2"
        >
          <img src="../../assets/icon/icon/plus-circle.svg" alt="" />
          <span class="text">Tambah Project</span></b-button
        >
      </div>
      <div class="inner-btn d-md-block d-lg-block d-xl-none">
        <b-button
          v-if="
            (loadingP != true && role == 'manager') ||
            role == 'admin' ||
            role == 'supervisor'
          "
          class="invite"
          @click="openModal1"
        >
          <img src="../../assets/icon/icon/mail.svg" alt="" />
          <div class="text">undang akun</div></b-button
        >
      </div>
    </div>
    <div class="button-wrapper">
      <div class="front">
        <span>Daftar Project</span>
        <div class="inner-btn d-md-none d-lg-none d-xl-block">
          <b-button
            v-if="loadingP != true"
            class="refresh"
            @click="() => this.$store.dispatch('apiListProject/getData')"
          >
            <img src="../../assets/icon/icon/rotate-cw.svg" alt="" />
          </b-button>
          <b-button class="refresh" disabled v-else>
            <img src="../../assets/icon/icon/rotate-cw.svg" alt="" />
          </b-button>
        </div>
        <div class="inner-btn d-md-none d-lg-none d-xl-block">
          <b-button
            v-if="
              (loadingP != true && role == 'manager') ||
              role == 'admin' ||
              role == 'supervisor'
            "
            class="project"
            @click="openModal2"
          >
            <img src="../../assets/icon/icon/plus-circle.svg" alt="" />
            <span class="text">Tambah Project</span></b-button
          >
        </div>
        <div class="inner-btn d-md-none d-lg-none d-xl-block">
          <b-button
            v-if="
              (loadingP != true && role == 'manager') ||
              role == 'admin' ||
              role == 'supervisor'
            "
            class="invite"
            @click="openModal1"
          >
            <img src="../../assets/icon/icon/mail.svg" alt="" />
            <div class="text">undang akun</div></b-button
          >
        </div>
      </div>
      <b-modal hide-footer hide-header centered ref="modal-add" size="lg">
        <div class="tutup" style="display: flex; justify-content: flex-end">
          <b-button
            @click="tutupModal1"
            style="background-color: white; border: none"
            ><img
              src="../../assets/icon/close.svg"
              alt=""
              style="width: 15px; height: 15px"
          /></b-button>
        </div>
        <div
          class="content"
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 1% 2%;
          "
        >
          <span
            class="tittle"
            style="
              font-family: 'nunito', sans-serif;
              font-size: 28px;
              font-weight: 700;
              text-transform: capitalize;
            "
          >
            Tambah Project
          </span>
          <div class="form" style="width: 95%; margin-top: 4%">
            <b-form-group
              label="Nama Project"
              label-for="input-formatter"
              class="mb-0"
              style="
                font-family: 'nunito', sans-serif;
                font-size: 20px;
                font-weight: 300;
              "
            >
              <b-form-input
                id="input-formatter"
                placeholder="Nama Project"
                style="border-radius: 10px; margin: 2% 0"
                size="lg"
                type="text"
                v-model="namaProject"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label="Tahun Project"
              label-for="input-formatter"
              class="mb-0"
              style="
                font-family: 'nunito', sans-serif;
                font-size: 20px;
                font-weight: 300;
              "
            >
              <b-form-input
                id="input-formatter"
                placeholder="Tahun Project"
                style="border-radius: 10px; margin: 2% 0"
                size="lg"
                type="number"
                v-model="tahunProject"
              ></b-form-input>
            </b-form-group>
            <div class="button-wrapper" style="margin-top: 8%">
              <b-button
                style="
                  background-color: #327ff2;
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  color: #fff;
                  border: none;
                  height: 57px;
                  border-radius: 10px;
                "
                block
                @click="doAdd"
                v-if="
                  loading2 == false && namaProject != '' && tahunProject != ''
                "
                >Tambah Project</b-button
              >
              <b-button
                style="
                  background-color: #327ff2;
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  color: #fff;
                  border: none;
                  height: 57px;
                  border-radius: 10px;
                "
                block
                v-else-if="loading2 == true"
                disabled
                ><white-spinner />
              </b-button>
              <b-button
                style="
                  background-color: #327ff2;
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  color: #fff;
                  border: none;
                  height: 57px;
                  border-radius: 10px;
                "
                block
                disabled
                v-else
                >Tambah Project</b-button
              >
            </div>
            <!-- </b-form-group> -->
          </div>
        </div>
      </b-modal>
      <b-modal hide-footer centered hide-header ref="modal-invite">
        <div class="tutup" style="display: flex; justify-content: flex-end">
          <b-button
            @click="tutupModal1"
            style="background-color: white; border: none"
            ><img
              src="../../assets/icon/close.svg"
              alt=""
              style="width: 15px; height: 15px"
          /></b-button>
        </div>
        <div
          style="
            text-align: center;
            font-size: 22px;
            font-weight: 700;
            font-family: 'nunito', sans-serif;
          "
        >
          Undang Akun
        </div>
        <div style="margin-bottom: 6%">
          <label class="typo__label">Masukan Email</label>
          <b-form-input
            id="input-1"
            v-model="email"
            type="email"
            placeholder="Enter email"
            required
          ></b-form-input>
          <!-- <multiselect
            v-model="value"
            :options="options"
            :multiple="true"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="true"
            placeholder="Pilih akun"
            label="nama"
            track-by="nama"
            :hideSelected="true"
            :preselect-first="false"
            style="font-family: 'nunito', sans-serif"
          >
            <template slot="selection" slot-scope="{ values, search, isOpen }"
              ><span
                class="multiselect__single"
                v-if="values.length &amp;&amp; !isOpen"
                >{{ values.length }} options selected</span
              ></template
            >
          </multiselect> -->
        </div>

        <span class="peran" style="">Peran</span>

        <b-form-radio-group label="Peran" v-slot="{ ariaDescribedby }">
          <b-form-radio
            v-model="jabatan"
            :aria-describedby="ariaDescribedby"
            name="peran"
            value="supervisor"
            >Supervisor</b-form-radio
          >
        </b-form-radio-group>

        <b-button
          block
          @click="doInvite"
          v-if="loading3 == false && jabatan != ''"
          style="
            margin-top: 4%;
            background-color: #327ff2;
            font-family: 'nunito', sans-serif;
            font-weight: 700;
            border: none;
          "
          >Undang</b-button
        >
        <b-button
          block
          v-else-if="loading3 == true"
          disabled
          style="
            margin-top: 4%;
            background-color: #327ff2;
            font-family: 'nunito', sans-serif;
            font-weight: 700;
            border: none;
          "
          ><white-spinner
        /></b-button>
        <b-button
          block
          disabled
          v-else
          style="
            margin-top: 4%;
            background-color: #327ff2;
            font-family: 'nunito', sans-serif;
            font-weight: 700;
            border: none;
          "
          >Undang</b-button
        >
      </b-modal>
      <b-modal
        class="selesai"
        hide-footer
        hide-header
        centered
        ref="modal-selesai"
        size="md"
      >
        <div
          class="content"
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            padding: 1% 2%;
          "
        >
          <span
            class="tittle"
            style="
              font-family: 'nunito', sans-serif;
              font-size: clamp(16px, 18px, 20px);
              font-weight: 700;
              text-transform: capitalize;
            "
          >
            Apakah anda yakin menandai project {{ naama }} sudah selesai?
          </span>
        </div>
        <div
          class="wrapper"
          style="
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 0 2%;
          "
        >
          <div
            class="button-wrapper"
            style="display: flex; width: 95%; justify-content: flex-end"
          >
            <b-button
              @click="selesai()"
              v-if="loading == false"
              style="
                background-color: #327ff2;
                border: none;
                font-family: 'nunito', sans-serif;
                font-weight: 700;
                box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
              "
              >Ya</b-button
            >
            <b-button
              disabled
              v-else
              style="
                background-color: #327ff2;
                border: none;
                font-family: 'nunito', sans-serif;
                font-weight: 700;
                box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
              "
              ><white-spinner
            /></b-button>
            <b-button
              variant="danger"
              @click="batalSelesai()"
              v-if="loading == false"
              style="
                border: none;
                margin-left: 10px;
                font-family: 'nunito', sans-serif;
                font-weight: 700;
                box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
              "
              >Tidak</b-button
            >
            <b-button
              variant="danger"
              disabled
              v-else
              style="
                border: none;
                margin-left: 10px;
                font-family: 'nunito', sans-serif;
                font-weight: 700;
                box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
              "
              ><white-spinner
            /></b-button>
          </div>
        </div>
      </b-modal>

      <b-modal
        class="selesai"
        hide-footer
        hide-header
        centered
        ref="modal-cancel"
        size="md"
      >
        <div
          class="content"
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            padding: 1% 2%;
          "
        >
          <span
            class="tittle"
            style="
              font-family: 'nunito', sans-serif;
              font-size: clamp(16px, 18px, 20px);
              font-weight: 700;
              text-transform: capitalize;
            "
          >
            Apakah anda yakin menandai project {{ naama }} sedang dalam
            pengerjaan?
          </span>
        </div>
        <div
          class="wrapper"
          style="
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 0 2%;
          "
        >
          <div
            class="button-wrapper"
            style="display: flex; width: 95%; justify-content: flex-end"
          >
            <b-button
              @click="cancel"
              v-if="loading == false"
              style="
                background-color: #327ff2;
                border: none;
                font-family: 'nunito', sans-serif;
                font-weight: 700;
                box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
              "
              >Ya</b-button
            >
            <b-button
              disabled
              v-else
              style="
                background-color: #327ff2;
                border: none;
                font-family: 'nunito', sans-serif;
                font-weight: 700;
                box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
              "
              ><white-spinner
            /></b-button>
            <b-button
              variant="danger"
              @click="batalCancel"
              v-if="loading == false"
              style="
                border: none;
                margin-left: 10px;
                font-family: 'nunito', sans-serif;
                font-weight: 700;
                box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
              "
              >Tidak</b-button
            >
            <b-button
              variant="danger"
              disabled
              v-else
              style="
                border: none;
                margin-left: 10px;
                font-family: 'nunito', sans-serif;
                font-weight: 700;
                box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
              "
              ><white-spinner
            /></b-button>
          </div>
        </div>
      </b-modal>

      <div class="search-box">
        <b-form-group
          label="Filter"
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              style="
                border-radius: 10px;
                font-family: 'nunito', sans-serif;
                border-radius: 7px;
              "
              type="search"
              placeholder="Masukan Kata Kunci"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </div>
    </div>

    <div class="table">
      <b-overlay :show="loadingP == true" rounded="sm">
        <b-table
          hover
          :items="projects"
          :filter="filter"
          :per-page="perPage"
          :current-page="currentPage"
          striped
          :fields="fields"
          show-empty
          style="
            text-align: center;
            font-family: 'nunito', sans-serif;
            text-transform: capitalize;
          "
        >
          <template #cell(namaProject)="data">
            <a
              style="cursor: pointer; color: #327ff2; font-weight: 600"
              @click="goProject(data.item.projectid)"
              @click.right="goProjectInNewTab(data.item.projectid)"
              @click.middle="goProjectInNewTab(data.item.projectid)"
              @contextmenu.prevent
              >{{ data.value }}</a
            >
          </template>
          <template #cell(aksi)="data">
            <div
              style="
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: space-evenly;
              "
            >
              <b-button
                @click="editProject(data.item.projectid)"
                style="
                  background-color: #f4b366;
                  border: none;
                  height: 28px;
                  justify-content: center;
                  width: 28px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <img src="../../assets/icon/edit-2.svg" alt="" />
              </b-button>
              <b-button
                variant="info"
                v-if="role == 'admin' && data.item.projectDeletedAt == null"
                @click="hapus(data.item.projectid)"
                style="
                  border: none;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 28px;
                  width: 28px;
                "
              >
                <img
                  src="../../assets/icon/archive.svg"
                  style="height: 19px"
                  alt=""
                />
              </b-button>
              <b-button
                variant="success"
                v-if="role == 'admin' && data.item.projectDeletedAt != null"
                @click="restore(data.item.projectid)"
                style="
                  border: none;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 28px;
                  width: 28px;
                "
              >
                <img
                  src="../../assets/icon/arrow-counterclockwise.svg"
                  alt=""
                />
              </b-button>
              <b-button
                v-show="
                  data.item.progressProject === 'Progress' && role === 'admin'
                "
                @click="openSelesai(data.item.projectid)"
                style="
                  background-color: #6adbbe;
                  border: none;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 28px;
                  width: 28px;
                "
              >
                <img
                  src="../../assets/icon/tick(1).svg"
                  alt=""
                  style="width: 10px"
                />
              </b-button>

              <b-button
                v-show="
                  data.item.progressProject === 'Done' && role === 'admin'
                "
                @click="openCancel(data.item.projectid)"
                style="
                  background-color: #6a6cdb;
                  border: none;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 28px;
                  width: 28px;
                "
              >
                <img
                  src="../../../public/cancel.svg"
                  alt=""
                  style="width: 18px"
                />
              </b-button>

              <b-button
                v-if="role == 'admin'"
                @click="destroy(data.item.projectid)"
                style="
                  background-color: #e0544e;
                  border: none;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 28px;
                  width: 28px;
                "
              >
                <img src="../../assets/icon/trash.svg" alt="" />
              </b-button>
            </div>
          </template>
        </b-table>
        <template #overlay>
          <div class="text-center">
            <b-spinner></b-spinner>
            <p>Mohon Tunggu</p>
          </div>
        </template>
      </b-overlay>
    </div>

    <div class="paginate">
      <b-pagination
        v-model="currentPage"
        :total-rows="projects.length"
        style="width: 50%; font-family: 'nunito', sans-serif"
        :per-page="perPage"
        align="fill"
        size="sm"
      ></b-pagination>
    </div>
    <div class="alert">
      <div>
        <b-alert
          dismissible
          style="width: 50%"
          variant="danger"
          fade
          :show="alert"
        >
          {{ pesan }}
        </b-alert>
        <b-alert
          dismissible
          style="width: 50%"
          variant="success"
          fade
          :show="alert2"
        >
          {{ pesan }}
        </b-alert>
      </div>
    </div>

    <!-- modal edit client -->
    <b-modal hide-footer hide-header centered ref="modal-edit" size="lg">
      <div class="tutup" style="display: flex; justify-content: flex-end">
        <b-button
          @click="tutupModal1"
          style="background-color: white; border: none"
          ><img
            src="../../assets/icon/close.svg"
            alt=""
            style="width: 15px; height: 15px"
        /></b-button>
      </div>
      <div
        class="content"
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 1% 2%;
        "
      >
        <span
          class="tittle"
          style="
            font-family: 'nunito', sans-serif;
            font-size: 28px;
            font-weight: 700;
            text-transform: capitalize;
          "
        >
          Edit Project {{ project.namaProject }}
        </span>
        <div class="form" style="width: 95%; margin-top: 4%">
          <b-form-group
            label="Nama Project"
            label-for="input-formatter"
            class="mb-0"
            style="
              font-family: 'nunito', sans-serif;
              font-size: 20px;
              font-weight: 300;
            "
          >
            <b-form-input
              id="input-formatter"
              placeholder="Nama Project"
              style="border-radius: 10px"
              size="lg"
              type="text"
              v-model="project.namaProject"
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            label="Tahun Project"
            label-for="input-formatter"
            class="mb-0"
            style="
              font-family: 'nunito', sans-serif;
              font-size: 20px;
              font-weight: 300;
            "
          >
            <b-form-input
              id="input-formatter"
              placeholder="Tahun Project"
              style="border-radius: 10px"
              size="lg"
              type="text"
              v-model="project.tahunProject"
            >
            </b-form-input>
          </b-form-group>
        </div>
      </div>
      <div
        class="wrapper"
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 0 2%;
        "
      >
        <div
          class="button-wrapper"
          style="display: flex; width: 95%; justify-content: flex-end"
        >
          <b-button
            @click="doEdit"
            v-if="loading == false"
            style="
              background-color: #327ff2;
              color: #fff;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              width: 25%;
              border: none;
              margin-top: 20px;
              height: 40px;
            "
            >Edit Project</b-button
          >
          <b-button
            disabled
            v-else
            style="
              background-color: #327ff2;
              color: #fff;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              width: 25%;
              border: none;
              margin-top: 20px;
              height: 40px;
            "
            ><white-spinner
          /></b-button>
        </div>
      </div>
    </b-modal>
    <!-- modal hapus project -->
    <b-modal
      class="hapus"
      hide-footer
      hide-header
      centered
      ref="modal-hapus"
      size="m"
    >
      <div
        class="content"
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 1% 2%;
        "
      >
        <span
          class="tittle"
          style="
            font-family: 'nunito', sans-serif;
            font-size: 28px;
            font-weight: 700;
            text-transform: capitalize;
          "
        >
          Apakah anda yakin mengarsip project?
        </span>
      </div>
      <div style="margin-top: 4%">
        <b-form-group
          label-size="lg"
          label-cols="4"
          label-cols-lg="3"
          label="Password"
          label-for="input-formatter"
        >
          <b-form-input
            id="input-formatter"
            placeholder="Masukan Password"
            style="border-radius: 10px; margin-bottom: 15px"
            size="lg"
            type="password"
            v-model="password"
          >
          </b-form-input>
        </b-form-group>
      </div>
      <div
        class="wrapper"
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 0 2%;
        "
      >
        <div
          class="button-wrapper"
          style="display: flex; width: 95%; justify-content: flex-end"
        >
          <b-button
            @click="doDelete()"
            v-if="loading == false"
            style="
              background-color: #327ff2;
              border: none;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
            "
            >Ya</b-button
          >
          <b-button
            disabled
            v-else
            style="
              background-color: #327ff2;
              border: none;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
            "
            ><white-spinner
          /></b-button>
          <b-button
            variant="danger"
            @click="batalDelete()"
            v-if="loading == false"
            style="
              border: none;
              margin-left: 10px;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
            "
            >Tidak</b-button
          >
          <b-button
            disabled
            variant="danger"
            v-else
            style="
              border: none;
              margin-left: 10px;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
            "
            ><white-spinner
          /></b-button>
        </div>
      </div>
    </b-modal>
    <b-modal
      class="destroy"
      hide-footer
      hide-header
      centered
      ref="modal-destroy"
      size="m"
    >
      <div
        class="content"
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 1% 2%;
        "
      >
        <span
          class="tittle"
          style="
            font-family: 'nunito', sans-serif;
            font-size: 28px;
            font-weight: 700;
            text-transform: capitalize;
          "
        >
          Apakah anda yakin menghapus project?
        </span>
      </div>
      <div style="margin-top: 4%">
        <b-form-group
          label-size="lg"
          label-cols="4"
          label-cols-lg="3"
          label="Password"
          label-for="input-formatter"
        >
          <b-form-input
            id="input-formatter"
            placeholder="Masukan Password"
            style="border-radius: 10px; margin-bottom: 15px"
            size="lg"
            type="password"
            v-model="password"
          >
          </b-form-input>
        </b-form-group>
      </div>
      <div
        class="wrapper"
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 0 2%;
        "
      >
        <div
          class="button-wrapper"
          style="display: flex; width: 95%; justify-content: flex-end"
        >
          <b-button
            @click="doDestroy()"
            v-if="loading == false"
            style="
              background-color: #327ff2;
              border: none;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
            "
            >Ya</b-button
          >
          <b-button
            disabled
            v-else
            style="
              background-color: #327ff2;
              border: none;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
            "
            ><white-spinner
          /></b-button>
          <b-button
            variant="danger"
            @click="batalDestroy()"
            v-if="loading == false"
            style="
              margin-left: 10px;
              border: none;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
            "
            >Tidak</b-button
          >
          <b-button
            variant="danger"
            disabled
            v-else
            style="
              margin-left: 10px;
              border: none;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
            "
            ><white-spinner
          /></b-button>
        </div>
      </div>
    </b-modal>
    <b-modal
      class="restore"
      hide-footer
      hide-header
      centered
      ref="modal-aktif"
      size="m"
    >
      <div
        class="content"
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 1% 2%;
        "
      >
        <span
          class="tittle"
          style="
            font-family: 'nunito', sans-serif;
            font-size: 28px;
            font-weight: 700;
            text-transform: capitalize;
          "
        >
          Apakah anda yakin ingin mengaktifkan client?
        </span>
      </div>
      <div style="margin-top: 4%">
        <b-form-group
          label-size="lg"
          label-cols="4"
          label-cols-lg="3"
          label="Password"
          label-for="input-formatter"
        >
          <b-form-input
            id="input-formatter"
            placeholder="Masukan Password"
            style="border-radius: 10px; margin-bottom: 15px"
            size="lg"
            type="password"
            v-model="password"
          >
          </b-form-input>
        </b-form-group>
      </div>
      <div
        class="wrapper"
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 0 2%;
        "
      >
        <div
          class="button-wrapper"
          style="display: flex; width: 95%; justify-content: flex-end"
        >
          <b-button
            @click="doRestore()"
            v-if="loading == false"
            style="
              background-color: #327ff2;
              border: none;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
            "
            >Ya</b-button
          >
          <b-button
            disabled
            v-else
            style="
              background-color: #327ff2;
              border: none;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
            "
            ><white-spinner
          /></b-button>
          <b-button
            variant="danger"
            @click="closeRestore()"
            v-if="loading == false"
            style="
              border: none;
              margin-left: 10px;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
            "
            >Tidak</b-button
          >
          <b-button
            variant="danger"
            disabled
            v-else
            style="
              background-color: #327ff2;
              border: none;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
            "
            ><white-spinner
          /></b-button>
        </div>
      </div>
    </b-modal>

    <b-modal centered ref="tidak-aktif" hide-footer title="PERHATIAN!">
      <div style="padding: 5%; font-size: 19px">
        Mohon maaf project silahkan pilih project lain
      </div>
      <center>
        <b-button style="background-color: #327ff2; border: none" @click="close"
          >Tutup</b-button
        >
      </center>
    </b-modal>
  </div>
</template>

<script>
import axios from "@/ip_config.js";
import { mapState } from "vuex";
import Multiselect from "vue-multiselect";
import WhiteSpinner from "../../components/spinner/white-spinner.vue";

export default {
  components: {
    WhiteSpinner,
    Multiselect,
  },

  data() {
    return {
      clientid: "",
      project: "",
      email: "",
      namaProject: "",
      tahunProject: "",
      alert: false,
      alert2: false,
      pid: "",
      password: "",
      loading: false,
      loading2: false,
      loading3: false,
      role: "",
      value: [],
      options: [],
      jabatan: "",
      perPage: 10,
      currentPage: 1,
      naama: "",
      pesan: "",
      fields: [
        {
          key: "nomer",
          label: "No",
        },
        {
          key: "namaProject",
          label: "Nama Project",
        },
        {
          key: "updateproject",
          label: "Tanggal Terakhir Diubah",
        },
        {
          key: "tahunProject",
          label: "Tahun",
        },
        {
          key: "aksi",
          label: "Aksi",
        },
      ],
      filter: null,
    };
  },
  mounted() {
    this.$store.dispatch("apiListClient/getData");
    this.role = localStorage.getItem("role");
  },
  computed: {
    ...mapState("apiListProject", ["projects", "loadingP"]),
    ...mapState("apiUser", ["stateId"]),
    ...mapState("apiProject", ["tidakAktif"]),
  },
  methods: {
    editProject(id) {
      let vm = this;

      axios
        .get("project/listById/" + id, {
          headers: { token: localStorage.getItem("token") },
        })
        .then((res) => {
          vm.project = res.data.data[0];
          vm.pid = id;
        });
      this.$refs["modal-edit"].show();
    },

    doEdit(id) {
      let vm = this;
      vm.loading = true;

      axios
        .post(
          "project/update/" + vm.pid,
          {
            namaProject: vm.project.namaProject,
            tahunProject: vm.project.tahunProject,
          },
          {
            headers: { token: localStorage.getItem("token") },
          }
        )
        .then(() => {
          vm.loading = false;
          this.$store.dispatch("apiListProject/getData");
          this.$refs["modal-edit"].hide();
        });
    },

    openSelesai(id) {
      this.naama = this.projects[0].namaProject;
      this.$refs["modal-selesai"].show();
      this.pid = id;
    },

    openCancel(id) {
      this.naama = this.projects[0].namaProject;
      this.$refs["modal-cancel"].show();
      this.pid = id;
    },

    selesai() {
      let vm = this;
      vm.loading = true;

      axios.post(
        "project/update/" + vm.pid,
        {
          progressProject: "Done",
        },
        {
          headers: { token: localStorage.getItem("token") },
        }
      );
      this.loading = false;
      this.$store.dispatch("apiListProject/getData");
      this.$refs["modal-selesai"].hide();
    },

    cancel() {
      let vm = this;
      vm.loading = true;
      let y = this.projects[0].projectid;

      axios.post(
        "project/update/" + y,
        {
          progressProject: "Progress",
        },
        {
          headers: { token: localStorage.getItem("token") },
        }
      );
      this.loading = false;
      this.$store.dispatch("apiListProject/getData");
      this.$refs["modal-cancel"].hide();
    },

    batalSelesai() {
      this.$refs["modal-selesai"].hide();
    },

    batalCancel() {
      this.$refs["modal-cancel"].hide();
    },

    async goProject(id) {
      await this.$store.dispatch("apiProject/getProject", id);
      if (this.tidakAktif || this.tidakAktif == "Project Tidak Aktif") {
        this.$refs["tidak-aktif"].show();
      } else {
        this.$router.push("/project/" + id);
      }
    },

    async goProjectInNewTab(id) {
      await this.$store.dispatch("apiProject/getProject", id);
      if (this.tidakAktif || this.tidakAktif == "Project Tidak Aktif") {
        this.$refs["tidak-aktif"].show();
      } else {
        //this.$router.push("/project/" + id);
        window.open("https://online.kapmobile.id/#/project/" + id, '');
      }
    },

    close() {
      this.$refs["tidak-aktif"].hide();
    },

    openModal2() {
      this.$refs["modal-add"].show();
    },

    openModal1() {
      this.$refs["modal-invite"].show();
      // let vm = this;
      // vm.loading4 = true;
      // let x = localStorage.getItem("token");
      // let y = localStorage.getItem("idClient");
      // axios
      //   .get(ip_server + "users/usersNotInClient/" + y, {
      //     headers: {
      //       token: x,
      //     },
      //   })
      //   .then((res) => {
      //     vm.options = res.data;

      //     vm.options.forEach((el, id) => {
      //       if (vm.options[id].id == vm.stateId) {
      //         let x = vm.options.indexOf(vm.options[id]);
      //         if (x > -1) {
      //           vm.options.splice(x, 1);
      //         }
      //       }
      //     });

      //     vm.options.forEach((el, id) => {
      //       if (vm.options[id].role == "superuser") {
      //         let x = vm.options.indexOf(vm.options[id]);
      //         if (x > -1) {
      //           vm.options.splice(x, 1);
      //         }
      //       }
      //     });
      //   });
    },

    tutupModal1() {
      this.$refs["modal-add"].hide();
      this.$refs["modal-invite"].hide();
      this.$refs["modal-edit"].hide();
    },

    doInvite() {
      let vm = this;
      // let kirim = [];
      let y = Number(localStorage.getItem("idKAP"));
      let z = Number(localStorage.getItem("idClient"));

      vm.loading3 = true;

      axios
        .post(
          "poolClient/invite",
          {
            email: vm.email,
            jabatan: vm.jabatan,
            clientId: z,
            KAPId: y,
          },
          {
            headers: {
              token: localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.data.message == "tidak punya authorization") {
            vm.pesan = "Anda tidak memiliki akses";
            vm.alert = true;
            setTimeout(() => {
              vm.alert = false;
            }, 3000);
          } else if (res.data.message == "anda tidak memiliki akses") {
            vm.pesan = "Anda tidak memiliki akses";
            vm.alert = true;
            setTimeout(() => {
              vm.alert = false;
            }, 3000);
          } else if (
            res.data.message ==
            "user telah terdaftar atau sedang menunggu konfirmasi"
          ) {
            vm.pesan = "Menunggu konformasi atau telah diundang";
            vm.alert = true;
            setTimeout(() => {
              vm.alert = false;
            }, 3000);
          } else if (res.data.message == "email tidak terdaftar") {
            vm.pesan = "Email Tidak Terdaftar";
            vm.alert = true;
            setTimeout(() => {
              vm.alert = false;
            }, 3000);
          } else {
            vm.pesan = "Undangan berhasil dikirim";
            vm.alert2 = true;
            setTimeout(() => {
              vm.alert2 = false;
            }, 3000);
          }
          vm.id = "";
          vm.jabatan = "";
          vm.value = [];
          console.log(res.data.message);
          this.$refs["modal-invite"].hide();
          vm.loading3 = false;
        });
    },

    doAdd() {
      let vm = this;
      vm.loading2 = true;
      let y = localStorage.getItem("idKAP");
      let z = localStorage.getItem("idClient");

      axios
        .post(
          "project/register",
          {
            namaProject: vm.namaProject,
            tahunProject: vm.tahunProject,
            clientId: Number(z),
            KAPId: Number(y),
          },
          {
            headers: { token: localStorage.getItem("token") },
          }
        )
        .then((res) => {
          if (res.data.message == "project sudah maksimal") {
            vm.pesan = "Project Sudah Maksimal";
            vm.alert = true;
            setTimeout(() => {
              vm.alert = false;
            }, 3000);
          } else if (res.data.message == "tahun project sudah ada") {
            vm.pesan = "Tahun Project Sudah Ada";
            vm.alert = true;
            setTimeout(() => {
              vm.alert = false;
            }, 3000);
          } else {
            vm.pesan = "Berhasil Membuat Project";
            vm.alert2 = true;
            setTimeout(() => {
              vm.alert2 = false;
            }, 3000);
          }
          this.$store.dispatch("apiListProject/getData");
          vm.loading2 = false;
          vm.namaProject = "";
          vm.tahunProject = "";
          this.$refs["modal-add"].hide();
        });
    },

    hapus(id) {
      this.id = id;
      this.$refs["modal-hapus"].show();
    },

    batalDelete() {
      this.$refs["modal-hapus"].hide();
    },

    doDelete() {
      let vm = this;
      let ids = vm.id;
      this.loading = true;

      axios
        .post(
          "project/delete",
          {
            id: ids,
            password: vm.password,
          },
          {
            headers: { token: localStorage.getItem("token") },
          }
        )
        .then((res) => {
          if (res.data.message == "password salah") {
            this.$refs["modal-hapus"].hide();
            vm.loading = false;
            vm.password = "";
            vm.pesan = "password salah";
            vm.alert = true;
            setTimeout(() => {
              vm.alert = false;
            }, 3000);
          } else if (vm.password == "") {
            this.$refs["modal-hapus"].hide();
            this.loading = false;
            vm.pesan = "masukan password";
            vm.alert = true;
            setTimeout(() => {
              vm.alert = false;
            }, 3000);
          } else {
            this.$refs["modal-hapus"].hide();
            this.loading = false;
            vm.password = "";
            vm.pesan = "project berhasil diarsip";
            vm.alert2 = true;
            setTimeout(() => {
              vm.alert2 = false;
            }, 3000);
            this.$store.dispatch("apiListProject/getData");
          }
        });
    },

    destroy(iid) {
      this.id = iid;
      this.$refs["modal-destroy"].show();
    },

    batalDestroy() {
      this.$refs["modal-destroy"].hide();
    },

    doDestroy() {
      let vm = this;
      let idx = vm.id;
      vm.loading = true;

      axios
        .post(
          "project/destroyProject",
          {
            projectId: idx,
            password: vm.password,
          },
          {
            headers: { token: localStorage.getItem("token") },
          }
        )
        .then((res) => {
          if (res.data.message == "password Salah") {
            this.$refs["modal-destroy"].hide();
            vm.loading = false;
            vm.password = "";
            vm.pesan = "password salah";
            vm.alert = true;
            setTimeout(() => {
              vm.alert = false;
            }, 3000);
          } else if (vm.password == "") {
            this.$refs["modal-destroy"].hide();
            this.loading = false;
            vm.pesan = "masukan password";
            vm.alert = true;
            setTimeout(() => {
              vm.alert = false;
            }, 3000);
          } else {
            this.$refs["modal-destroy"].hide();
            this.loading = false;
            vm.password = "";
            vm.pesan = "project berhasil dihapus";
            vm.alert = true;
            setTimeout(() => {
              vm.alert = false;
            }, 3000);
            this.$store.dispatch("apiListProject/getData");
          }
        });
    },

    restore(iii) {
      this.id = iii;
      this.$refs["modal-aktif"].show();
    },

    closeRestore() {
      this.$refs["modal-aktif"].hide();
    },

    doRestore() {
      let vm = this;
      let id = vm.id;
      let y = localStorage.getItem("idKAP");
      this.loading = true;

      axios
        .post(
          "project/restoreProject",
          {
            projectId: id,
            password: vm.password,
            KAPId: y,
          },
          {
            headers: { token: localStorage.getItem("token") },
          }
        )
        .then((res) => {
          if (res.data.message == "password Salah") {
            this.$refs["modal-aktif"].hide();
            vm.loading = false;
            vm.password = "";
            vm.pesan = "password salah";
            vm.alert = true;
            setTimeout(() => {
              vm.alert = false;
            }, 3000);
          } else if (vm.password == "") {
            this.$refs["modal-aktif"].hide();
            this.loading = false;
            vm.pesan = "masukan password";
            vm.alert = true;
            setTimeout(() => {
              vm.alert = false;
            }, 3000);
          } else if (res.data.message == "project sudah maksimal") {
            this.$refs["modal-aktif"].hide();
            this.loading = false;
            vm.password = "";
            vm.pesan = "Project Sudah Maksimal";
            vm.alert = true;
            setTimeout(() => {
              vm.alert = false;
            }, 3000);
          } else {
            this.$refs["modal-aktif"].hide();
            this.loading = false;
            vm.password = "";
            vm.pesan = "Project berhasil diaktivkan kembali";
            vm.alert2 = true;
            setTimeout(() => {
              vm.alert2 = false;
            }, 3000);
            this.$store.dispatch("apiListProject/getData");
          }
        });
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");

$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "white": #fafafa,
  "white2": #fff,
  "gray-dark": #b5bac3,
  "blue-light": #e8f3fe,
  "red-light": #ffe8e7,
  "green-light": #eafffa,
  "green": #dba66a,
);
.inner-btn {
  margin: 0 2%;
  .refresh {
    height: 28px;
    display: flex;
    align-items: center;
    background-color: map-get($color, "blue");
    box-shadow: 0px 20px 25px -10px rgba(15, 93, 210, 0.25);
    font-family: "nunito", sans-serif;
    text-transform: capitalize;
    border: none;
  }
  .project {
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: map-get($color, "blue");
    font-family: "nunito", sans-serif;
    text-transform: capitalize;
    border: none;
    .text {
      font-size: 14px;
      color: #fff;
    }
    img {
      width: 14%;
    }
  }
  .invite {
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: map-get($color, "blue");
    font-family: "nunito", sans-serif;
    text-transform: capitalize;
    border: none;
    .text {
      font-size: 14px;
    }
    img {
      width: 14%;
    }
  }
}

.wrapper {
  .button-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .front {
      display: flex;
      align-items: center;
      width: 60%;
      font-family: "nunito", sans-serif;
      span {
        color: map-get($color, "black");
        font-size: 28px;
      }
      .inner-btn {
        margin: 0 2%;
        .refresh {
          height: 28px;
          display: flex;
          align-items: center;
          background-color: map-get($color, "blue");
          box-shadow: 0px 20px 25px -10px rgba(15, 93, 210, 0.25);
          font-family: "nunito", sans-serif;
          text-transform: capitalize;
          border: none;
        }
        .project {
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: map-get($color, "blue");
          font-family: "nunito", sans-serif;
          text-transform: capitalize;
          border: none;
          .text {
            font-size: 14px;
            color: #fff;
          }
          img {
            width: 14%;
          }
        }
        .invite {
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: map-get($color, "blue");
          font-family: "nunito", sans-serif;
          text-transform: capitalize;
          border: none;
          .text {
            font-size: 14px;
          }
          img {
            width: 14%;
          }
        }
      }
    }
    .search-box {
      width: 50%;
    }
  }

  .table2 {
    width: 100%;
    font-family: "nunito", sans-serif;
    table {
      width: 100%;
      text-align: center;
      tr {
        border-top: 1px solid map-get($color, "gray-dark");
        border-bottom: 1px solid map-get($color, "gray-dark");
        th {
          padding: 2% 0;
        }
        td {
          padding: 1.5% 1.5%;
        }
      }
    }
  }

  .paginate {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .alert {
    width: 100%;
    z-index: 10;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
  }
}
</style>
