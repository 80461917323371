<template>
  <div class="wrapper">
    <div class="front">
      <span>Akun Terdaftar</span>
    </div>

    <div class="table" v-if="loading == false">
      <b-table
        hover
        striped
        show-empty
        :items="items"
        :fields="fields"
        style="
          text-align: center;
          font-family: 'nunito', sans-serif;
          text-transform: capitalize;
        "
      >
        <template #cell(aksi)="data">
          <div
            style="
              display: flex;
              align-items: center;
              width: 100%;
              justify-content: space-evenly;
            "
          >
            <b-button
              v-if="role == 'admin' && data.item.jabatan != 'Admin'"
              @click="hapus(data.item.idPool)"
              style="
                background-color: #e0544e;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 28px;
                width: 28px;
              "
            >
              <img src="../../assets/icon/trash.svg" alt="" />
            </b-button>
          </div>
        </template>
      </b-table>
    </div>

    <div class="table2" v-else>
      <table>
        <tr>
          <th>No</th>
          <th>Nama</th>
          <th>Peran</th>
          <th>Aksi</th>
        </tr>

        <tr v-for="q in 10" :key="q">
          <td>
            <b-skeleton animation="throb"></b-skeleton>
          </td>
          <td>
            <b-skeleton animation="throb"></b-skeleton>
          </td>
          <td>
            <b-skeleton animation="throb"></b-skeleton>
          </td>
          <td>
            <b-skeleton animation="throb"></b-skeleton>
          </td>
        </tr>
      </table>
    </div>

    <div class="paginate" v-if="loading == false && items.length > 10">
      <b-pagination
        v-model="currentPage"
        :total-rows="items.length"
        style="width: 50%; font-family: 'nunito', sans-serif"
        :per-page="perPage"
        size="sm"
        align="fill"
      ></b-pagination>
    </div>
    <b-modal
      class="hapus"
      hide-footer
      hide-header
      centered
      ref="modal-hapus"
      size="m"
    >
      <div
        class="content"
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 1% 2%;
        "
      >
        <span
          class="tittle"
          style="
            font-family: 'nunito', sans-serif;
            font-size: 28px;
            font-weight: 700;
            text-transform: capitalize;
          "
        >
          Apakah anda yakin menghapus?
        </span>
      </div>
      <div style="margin-top: 4%">
        <b-form-group
          label-size="lg"
          label-cols="4"
          label-cols-lg="3"
          label="Password"
          label-for="input-formatter"
        >
          <b-form-input
            id="input-formatter"
            placeholder="Masukan Password"
            style="border-radius: 10px; margin-bottom: 15px"
            size="lg"
            type="password"
            v-model="password"
          >
          </b-form-input>
        </b-form-group>
      </div>
      <div
        class="wrapper"
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 0 2%;
        "
      >
        <div
          class="button-wrapper"
          style="display: flex; width: 95%; justify-content: flex-end"
        >
          <b-button
            @click="deleteUser()"
            v-if="loading == false"
            size="sm"
            :disabled="!password"
            style="
              background-color: #327ff2;
              margin-right: 10px;
              border: none;
              color: map-get($color, 'white2');
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
            "
            >Ya</b-button
          >
          <b-button
            disabled
            v-else
            style="
              background-color: #327ff2;
              color: #fff;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              width: 50px;
              height: 25px;
              border: none;
              margin-top: 20px;
              margin-right: 20px
              height: 40px;
            "
            ><white-spinner
          /></b-button>
          <b-button
            @click="batalDelete()"
            v-if="loading == false"
            size="sm"
            style="
              background-color: #e0544e;
              margin-right: 10px;
              border: none;
              color: map-get($color, 'white2');
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
            "
            >Tidak</b-button
          >
          <b-button
            disabled
            v-else
            style="
              background-color: #327ff2;
              color: #fff;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              width: 50px;
              height: 25px;
              border: none;
              margin-top: 20px;
              height: 40px;
            "
            ><white-spinner
          /></b-button>
        </div>
      </div>
    </b-modal>
    <b-alert dismissible class="alert" :variant="variant" fade :show="show">
      {{ pesan }}
    </b-alert>
  </div>
</template>

<script>
import axios from "@/ip_config.js";

export default {
  data() {
    return {
      role: "",
      id: "",
      fields: [
        {
          key: "nomer",
          label: "No",
        },
        {
          key: "nama",
          label: "Nama",
        },
        {
          key: "jabatan",
          label: "Peran",
        },

        {
          key: "aksi",
          label: "Aksi",
        },
      ],
      items: [],
      loading: false,
      busy: false,
      variant: "",
      pesan: "",
      show: false,
      password: "",
    };
  },
  mounted() {
    this.getData();
    this.role = localStorage.getItem("role");
  },
  methods: {
    hapus(id) {
      this.id = id;
      this.$refs["modal-hapus"].show();
    },
    batalDelete() {
      this.$refs["modal-hapus"].hide();
    },
    async deleteUser() {
      let vm = this;
      vm.busy = true;

      let POST_DELETE = await axios.post(
        "poolClient/delete",
        {
          KAPId: localStorage.getItem("idKAP"),
          id: vm.id,
        },
        {
          headers: {
            token: localStorage.getItem("token"),
          },
        }
      );
      if (POST_DELETE.data.message == "sukses") {
        this.$refs["modal-hapus"].hide();
        this.loading = false;
        vm.pesan = "Akun berhasil dihapus";
        vm.variant = "success";
        vm.show = true;
        vm.loading = false;
        vm.password = "";
        vm.getData();
        setTimeout(() => {
          vm.show = false;
        }, 3000);
      } else {
        this.$refs["modal-hapus"].hide();
        this.loading = false;
        vm.pesan = "Silahkan coba beberapa saat lagi";
        vm.variant = "danger";
        vm.show = true;
        vm.loading = false;
        vm.getData();
        setTimeout(() => {
          vm.show = false;
        }, 3000);
      }
    },
    getData() {
      let vm = this;
      vm.loading = true;
      let y = localStorage.getItem("idClient");

      axios
        .post(
          "client/usersByClient",
          {
            id: y,
          },
          {
            headers: {
              token: localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.data.length == 0) {
            vm.items = [];
          } else {
            vm.items = res.data;
            res.data.forEach((element, index) => {
              let x = this.items[index];
              x.nomer = index + 1;
            });
          }
          setTimeout(() => {
            vm.loading = false;
          }, 1500);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");

$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "white": #fafafa,
  "white2": #fff,
  "gray-dark": #b5bac3,
  "blue-light": #e8f3fe,
  "red-light": #ffe8e7,
  "green-light": #eafffa,
  "green": #6adbbe,
);

.wrapper {
  .front {
    font-family: "nunito", sans-serif;
    font-size: 28px;
    font-weight: 300;
    padding-bottom: 1%;
  }

  .table {
    max-height: 450px;
  }

  .table2 {
    width: 100%;
    font-family: "nunito", sans-serif;
    table {
      width: 100%;
      text-align: center;
      tr {
        border-top: 1px solid map-get($color, "gray-dark");
        border-bottom: 1px solid map-get($color, "gray-dark");
        th {
          padding: 2% 0;
        }
        td {
          padding: 1.5% 1.5%;
        }
      }
    }
  }

  .paginate {
    transform: translateY(270px);
    position: sticky;
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .alert {
    position: absolute;
    width: 50%;
    margin: auto;
    left: 0;
    right: 0;
  }
}
</style>
