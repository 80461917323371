<template>
  <div>
    <section class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
      <nav_bar />
      <div class="top">
        <b-breadcrumb>
          <b-breadcrumb-item @click="goBack2()"> Dashboard </b-breadcrumb-item>
          <b-breadcrumb-item id="target1" @click="goBack()">
            <b-tooltip target="target1" triggers="hover">{{
              client.namaKAP
            }}</b-tooltip>
            {{ bcKAP }}</b-breadcrumb-item
          >
          <b-breadcrumb-item id="target2" active>
            <b-tooltip target="target2" triggers="hover">{{
              client.namaClient
            }}</b-tooltip
            >{{ bcClient }}</b-breadcrumb-item
          >
        </b-breadcrumb>
        <div class="button-wrapper">
          <b-button
            :class="{ tabActive: currentTab == 1 }"
            @click="selectTab(1)"
            style="margin-right: 0%"
            >Profil Perusahan</b-button
          >
          <b-button
            :class="{ tabActive: currentTab == 2 }"
            @click="selectTab(2)"
            >Daftar Project
          </b-button>
        </div>
      </div>

      <b-container fluid style="margin-top: 115px">
        <b-row style="padding: 0 1%">
          <b-col v-if="currentTab == 1" style="margin-top: 4%">
            <b-row>
              <b-col
                md="4"
                lg="4"
                xl="2"
                style="border-right: 1px solid #c8d5ec"
              >
                <profile_user_client :currentTab="currentTab" />
              </b-col>
              <b-col
                md="8"
                lg="8"
                xl="4"
                style="border-right: 1px solid #c8d5ec"
              >
                <profile_client :currentTab="currentTab" />
              </b-col>
              <b-col md="12" lg="12" xl="6">
                <table_supervisor />
              </b-col>
            </b-row>
          </b-col>
          <b-col v-else style="margin-top: 4%">
            <b-row>
              <b-col
                xl="2"
                class="d-md-none d-lg-none d-xl-block"
                style="border-right: 1px solid #c8d5ec"
              >
                <profile_user_client
                  :namaClient="this.client.namaClient"
                  :currentTab="currentTab"
                />
              </b-col>
              <b-col md="12" lg="12" xl="10">
                <table_project />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import axios from "@/ip_config.js";
import nav_bar from "../../../components/nav_bar.vue";
import profile_user_client from "../../../components/details/profile_user_client.vue";
import profile_client from "../../../components/details/profile_client.vue";
import tableUser from "../../../components/table/table_user.vue";
import Table_project from "../../../components/table/table_project.vue";
import table_supervisor from "../../../components/table/table_ supervisor.vue";
export default {
  components: {
    profile_user_client,
    profile_client,
    nav_bar,
    table_user: tableUser,
    Table_project,
    table_supervisor,
  },
  data() {
    return {
      client: [],
      currentTab: 1,
      bcKAP: "",
      bcClient: "",
    };
  },

  mounted() {
    this.getData();
    // localStorage.setItem("pembanding", "");
  },

  methods: {
    selectTab(selected) {
      let vm = this;
      vm.currentTab = selected;
    },

    async getData() {
      let vm = this;
      let z = localStorage.getItem("token");

      await axios
        .get("client/listById/" + localStorage.getItem("idClient"), {
          headers: {
            token: z,
          },
        })
        .then((res) => {
          localStorage.setItem("idKAP", res.data.data[0].KAPId);
          vm.client = res.data.data[0];
          if (this.client.namaKAP.length <= 12) {
            this.bcKAP = this.client.namaKAP;
          } else {
            this.bcKAP = this.client.namaKAP.substring(0, 13) + "...";
          }

          if (this.client.namaClient.length <= 12) {
            this.bcClient = this.client.namaClient;
          } else {
            this.bcClient = this.client.namaClient.substring(0, 13) + "...";
          }
          localStorage.setItem("idClient", vm.client.clientid);
        });
    },
    goBack() {
      let y = localStorage.getItem("idKAP");
      this.$router.push("/profile_kap/" + y);
    },
    goBack2() {
      this.$router.push("/main_dashboard");
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");

$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "white": #fafafa,
  "white2": #fff,
  "gray-dark": #b5bac3,
  "blue-light": #e8f3fe,
  "red-light": #ffe8e7,
  "green-light": #eafffa,
  "green": #6adbbe,
);
.container-fluid {
  min-height: 100vh;
  background-color: map-get($color, "white");
}
.bg {
  position: fixed;
  height: 20%;
  width: 100%;
  background-color: #327ff2;
  // background-image: url("../../../assets/vector/Pattern.svg");
  // background-repeat: repeat;
  // background-blend-mode: soft-light;
}
.top {
  width: 96%;
  height: 65px;
  background-color: map-get($color, "white2");
  box-shadow: 0px 10px 35px -10px rgba(123, 156, 160, 0.1);
  border-radius: 10px;
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
  display: flex;

  .breadcrumb {
    width: 100%;
    font-weight: 700;
    font-family: "nunito", sans-serif;
    text-transform: capitalize;
    transform: translateY(16%);
    background-color: transparent;
    color: map-get($color, "blue");
  }
  .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    .btn {
      font-family: "nunito", sans-serif;
      color: map-get($color, "blue");
      font-weight: 700;
      background-color: map-get($color, "white");
      border: 2px solid #327ff2;
      text-transform: capitalize;
      border-radius: 10px;
      margin: 0 2%;
      padding: 0.5% 2%;
      height: 40px;
    }
    .tabActive {
      font-family: "nunito", sans-serif;
      color: map-get($color, "white");
      font-weight: 700;
      background-color: map-get($color, "blue");
      border: 2px solid #fff;
      text-transform: capitalize;
      box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
      border-radius: 10px;
      height: 40px;
      margin: 0 2%;
      padding: 0.5% 2%;
      border: 2px solid map-get($color, "blue");
    }
  }
}
</style>
