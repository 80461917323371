<template>
  <div class="wrapper">
    <b-table
      show-empty
      hover
      striped
      :fields="fields"
      style="text-align: center"
    >
      <template #cell(aksi)="">
        
        <b-button
          pill
          @click="editClient()"
          style="
            background-color: #f4b366;
            border: none;
            font-family: 'nunito';
            font-weight: 700;
            margin: 2%;
          "
        >
          <img src="../../assets/icon/edit-2.svg" alt="" />
          edit</b-button
        >
        <b-button
          pill
          @click="doDelete()"
          style="
            background-color: #e0544e;
            border: none;
            font-family: 'nunito';
            font-weight: 700;
          "
        >
          <img src="../../assets/icon/trash.svg" alt="" />
          hapus</b-button
        ></template
      >
    </b-table>
  </div>
</template>

<script>
// import axios from "axios";
// import { ip_server } from "@/ip_config.js";
export default {
  name: "tableUser",
  data() {
    return {
      fields: [
        {
          key: "nomer",
          label: "No",
        },
        {
          key: "namaAkun",
          label: "Nama Akun",
        },
        {
          key: "role",
          label: "Peran",
        },
        {
          key: "aksi",
          label: "Aksi",
        },
      ],
    };
  },
};
</script>

<style>
</style>