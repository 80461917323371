<template>
  <div class="wrapper">
    <div class="nama">
      <span>Profile Perusahaan</span>
      <div class="button-wrapper2">
        <b-button
          v-if="
            roleLocal == 'admin' ||
              roleLocal == 'manager' ||
              roleLocal == 'supervisor'
          "
          @click="editClient"
          >Edit</b-button
        >
      </div>
      <b-modal hide-footer hide-header centered ref="modal-edit" size="lg">
        <div style="display: flex; justify-content: flex-end">
          <b-button
            @click="tutupModal1"
            style="background-color: white; border: none"
            ><img
              src="../../assets/icon/close.svg"
              alt=""
              style="width: 15px; height: 15px"
          /></b-button>
        </div>
        <div
          class="content"
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 1% 2%;
          "
        >
          <span
            class="tittle"
            style="
              font-family: 'nunito', sans-serif;
              font-size: 28px;
              font-weight: 700;
              text-transform: capitalize;
            "
          >
            Edit {{ client.namaClient }}
          </span>
          <div class="form" style="width: 95%; margin-top: 4%">
            <b-form-group
              label="Alamat Perusahaan"
              label-for="input-formatter"
              class="mb-0"
              style="
                font-family: 'nunito', sans-serif;
                font-size: 20px;
                font-weight: 300;
              "
            >
              <b-form-input
                id="input-formatter"
                placeholder="Alamat Perusahaan"
                style="border-radius: 10px"
                size="lg"
                type="text"
                v-model="client.alamatClient"
                >{{ client.alamatClient }}</b-form-input
              >
            </b-form-group>
            <b-form-group
              label="Nomor Telepon Perusahaan"
              label-for="input-formatter"
              class="mb-0"
              style="
                font-family: 'nunito', sans-serif;
                font-size: 20px;
                font-weight: 300;
              "
            >
              <b-form-input
                id="input-formatter"
                placeholder="Nomor Telepon Perusahaan"
                style="border-radius: 10px"
                size="lg"
                type="number"
                v-model="client.noHpClient"
              >
                {{ client.noHpClient }}
              </b-form-input>
            </b-form-group>
            <b-form-group
              label="Email Perusahaan"
              label-for="input-formatter"
              class="mb-0"
              style="
                font-family: 'nunito', sans-serif;
                font-size: 20px;
                font-weight: 300;
              "
            >
              <b-form-input
                id="input-formatter"
                placeholder="Email Perusahaan"
                style="border-radius: 10px"
                size="lg"
                type="text"
                v-model="client.emailClient"
              >
                {{ client.emailClient }}
              </b-form-input>
            </b-form-group>
            <b-form-group
              label="Website Perusahaan"
              label-for="input-formatter"
              class="mb-0"
              style="
                font-family: 'nunito', sans-serif;
                font-size: 20px;
                font-weight: 300;
              "
            >
              <b-form-input
                id="input-formatter"
                placeholder="Website Perusahaan"
                style="border-radius: 10px"
                size="lg"
                type="text"
                v-model="client.websiteClient"
              >
                {{ client.websiteClient }}
              </b-form-input>
            </b-form-group>
          </div>
        </div>
        <div
          style="
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 0 2%;
          "
        >
          <div
            class="button-wrapper"
            style="display: flex; width: 95%; justify-content: flex-end"
          >
            <b-button
              @click="doEdit"
              block
              v-if="loading2 == false"
              style="
                background-color: #327ff2;
                color: #fff;
                font-family: 'nunito', sans-serif;
                font-weight: 700;
                width: 25%;
                border: none;
                margin-top: 20px;
                height: 40px;
              "
              >Edit KAP</b-button
            >
            <b-button
              disabled
              v-else
              style="
                background-color: #327ff2;
                color: #fff;
                font-family: 'nunito', sans-serif;
                font-weight: 700;
                width: 25%;
                border: none;
                margin-top: 20px;
                height: 40px;
              "
              ><white-spinner
            /></b-button>
          </div>
        </div>
      </b-modal>
    </div>

    <div class="details">
      <div class="wrapper-inner">
        <div class="rows">
          <span class="title">alamat</span>
          <span
            class="isi"
            v-if="client.alamatClient == '' && loadingC == false"
            >Alamat Perusahaan belum diisi</span
          >
          <b-skeleton
            class="loading"
            v-else-if="loadingC == true"
            animation="throb"
          ></b-skeleton>
          <span class="isi" v-else>{{ client.alamatClient }}</span>
        </div>

        <div class="rows">
          <span class="title">telfon</span>
          <span class="isi" v-if="client.noHpClient == '' && loadingC == false"
            >nomor telepon belum diisi</span
          >
          <b-skeleton
            class="loading"
            v-else-if="loadingC == true"
            animation="throb"
          ></b-skeleton>
          <span class="isi" v-else>{{ client.noHpClient }}</span>
        </div>

        <div class="rows">
          <span class="title">email</span>
          <span
            class="isi"
            v-if="client.emailClient == null && loadingC == false"
            >alamat email belum diisi</span
          >
          <b-skeleton
            class="loading"
            v-else-if="loadingC == true"
            animation="throb"
          ></b-skeleton>
          <span class="isi" v-else style="text-transform: inherit">{{
            client.emailClient
          }}</span>
        </div>

        <div class="rows">
          <span class="title">website</span>
          <span
            class="isi"
            v-if="client.websiteClient == null && loadingC == false"
            >alamat website belum diisi</span
          >
          <b-skeleton
            class="loading"
            v-else-if="loadingC == true"
            animation="throb"
          ></b-skeleton>
          <a
            class="isi"
            :href="'https://' + client.websiteClient"
            v-else
            target="blank"
            style="
              text-decoration: underline;
              text-transform: inherit;
              color: #327ff2;
              cursor: pointer;
            "
            >{{ client.websiteClient }}</a
          >
        </div>
      </div>

      <!---------------------------------------------------------- DROP DOWN ---------------------------------------------------------->
      <div class="rows2">
        <b-button block @click="$refs['gambaran'].show()"
          >Gambaran Umum</b-button
        >
      </div>

      <div class="rows2">
        <b-button block @click="$refs['kebijakan'].show()"
          >Kebijakan Akuntansi</b-button
        >
      </div>
    </div>

    <b-modal
      centered
      size="xl"
      ref="kebijakan"
      hide-footer
      title="Kebijakan Akuntansi"
    >
      <div style="padding-right: 2%">
        <ol>
          <li style="margin-bottom: 1%">
            <span style="font-weight: 600; font-size: 17px"
              >Penerapan Standar Akuntansi Keuangan Entitas Tanpa Akuntabilitas
              Publik (SAK ETAP)</span
            >
            <ul style="text-align: justify; margin-top: 1%">
              <li style="margin-top: 1%">
                Berdasarkan persyaratan dan kriteria dalam Standar Akuntansi
                Keuangan Entitas Tanpa Akuntabilitas Publik (SAK ETAP),
                Perusahaan memenuhi kriteria sebagai entitas tanpa akuntabilitas
                publik. Oleh karena itu manajemen perusahaan memutuskan untuk
                menerapkan SAK ETAP sebagai basis dalam penyusunan dan penyajian
                laporan keuangan Perusahaan.
              </li>
              <li style="margin-top: 1%">
                Apabila dibandingkan dengan persyaratan dalam Standar Akuntansi
                Keuangan yang berlaku maka persayaratan dalam SAK ETAP lebih
                sederhana. Demikian juga apabila dibandingkan dengan
                perkembangan terkini SAK yang saat ini sedang dalam proses
                konverjensi dengan International Financial Reporting Standard
                (IFRS), maka persyaratan dalam SAK ETAP juga lebih sederhana.
              </li>
              <li style="margin-top: 1%">
                Perusahaan memilih untuk menerapkan SAK ETAP, dengan
                pertimbangan bahwa informasi yang disajikan dalam laporan
                keuangan berdasarkan SAK ETAP masih mampu mencerminkan substansi
                ekonomi dari kegiatan operasi dan bisnis perusahaan.
                Pertimbangan lainnya adalah biaya dan mandaat dalam penyusunan
                laporan keuangan berdasarkan SAK ETAP lebih efisien bagi
                perusahaan.
              </li>
              <li style="margin-top: 1%">
                Meskipun persayaratan dalam SAK ETAP lebih sederhana
                dibandingkan dengan perkembangan terkini SAK, namun Perusahaan
                tetap mengedepankan penyajian wajar dan pengungkapaan secara
                penuh atas informasi keuangan yang relevan dan andal bagi
                pemakai sebagaimana disyaratkan oleh standar tersebut. Oleh
                karena itu tujuan penyajian laporan keuangan bagi sebagian besar
                pemakai tetap terpenuhi.
              </li>
            </ul>
          </li>

          <li style="margin-bottom: 1%">
            <span style="font-weight: 600; font-size: 17px"
              >Pernyataan Kepatuhan Terhadap SAK ETAP</span
            >
            <ul>
              <li style="margin-top: 1%">
                Manajemen Perusahaan menyatakan bahwa laporan keuangan tahun
                2019 disajikan sesuai dengan SAK ETAP dan telah memenuhi semua
                persyaratannya.
              </li>
            </ul>
          </li>

          <li style="margin-bottom: 1%">
            <span style="font-weight: 600; font-size: 17px"
              >Kas dan Setara Kas</span
            >
            <ul>
              <li style="margin-top: 1%">
                Kas (bank) dan semua investasi yang jatuh tempo dalam waktu tiga
                bulan atau kurang dari tanggal perolehannya dan yang tidak
                dijaminkan serta tidak dibatasi penggunaannya dianggap sebagai
                setara kas.
              </li>
            </ul>
          </li>

          <li style="margin-bottom: 1%">
            <span style="font-weight: 600; font-size: 17px"
              >Piutang Dagang</span
            >
            <ul>
              <li style="margin-top: 1%">
                Akun Piutang Dagang disajikan sebesar nilai bruto dari hak
                perusahaan kepada pihak lain, tidak dibentuk cadangan atas
                kemungkinan tidak tertagihnya piutang. Kerugian piutang yang
                mungkin timbul, akan dicatat dengan metode langsung dan
                dibebankan pada periode terjadinya.
              </li>
            </ul>
          </li>

          <li style="margin-bottom: 1%">
            <span style="font-weight: 600; font-size: 17px"
              >Uang Muka Pembelian</span
            >
            <ul>
              <li style="margin-top: 1%">
                Uang Muka Pembelian disajikan sebesar nilai bruto dari yang
                dibayarkan dimuka kepada perusahaan Pihak lain, yang belum
                terealisasi/terbebankan pada tahun berjalan.
              </li>
            </ul>
          </li>

          <li style="margin-bottom: 1%">
            <span style="font-weight: 600; font-size: 17px"
              >Biaya Dibayar Dimuka</span
            >
            <ul>
              <li style="margin-top: 1%">
                Biaya Dibayar Dimuka disajiakn sebesar nilai bruto yang
                dibayarkan dimuka kepada pihak lain atas biaya yang belum
                terselesaikan.
              </li>
            </ul>
          </li>

          <li style="margin-bottom: 1%">
            <span style="font-weight: 600; font-size: 17px">Aset Tetap</span>
            <ul>
              <li style="margin-top: 1%">
                Aset tetap dinyatakan berdasarkan biaya perolehan setelah
                dikurangi akumulasi penyusutan.

                <div class="table" style="margin-top: 1%">
                  <div class="detil">
                    Aset tetap disusutkan dengan menggunakan metode garis lurus
                    (straight line method) berdasarkan taksiran masa manfaat
                    ekonomis aset tetap sebagai berikut :
                  </div>

                  <table
                    style="
                      margin-top: 1%;
                      border: none;
                      width: 60%;
                      margin-bottom: 1%;
                    "
                  >
                    <thead style="width: 100%">
                      <tr style="width: 100%">
                        <th style="width: 20%">Jenis Aset</th>
                        <th style="width: 20%">Masa Manfaat</th>
                        <th style="width: 20%">Tarif Penyusutan</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1. Bangunan</td>
                        <td>20 tahun</td>
                        <td>5%</td>
                      </tr>
                      <tr>
                        <td>2. Investasi Kantor</td>
                        <td>4 tahun</td>
                        <td>25%</td>
                      </tr>
                      <tr>
                        <td>3. Kendaraan</td>
                        <td>5 tahun</td>
                        <td>20%</td>
                      </tr>
                      <tr>
                        <td>4. Mesin Produksi</td>
                        <td>8 tahun</td>
                        <td>12,5%</td>
                      </tr>
                      <tr>
                        <td>5. Peralatan</td>
                        <td>4 & 8 tahun</td>
                        <td>12,5% & 25%</td>
                      </tr>
                    </tbody>
                  </table>

                  <div class="detil">
                    Beban pemeliharaan dan perbaikan dibebankan pada laporan
                    laba rugi pada saat terjadinya; pengeluaran yang
                    memperpanjang masa manfaat atau memberi manfaat ekonomik
                    dimasa yang akan datang dikapitalisasi. Aset tetap yang
                    sudah tidak digunakan lagi atau yang dijual dikeluarkan dari
                    kelompok aset tetap berikut akumulasi penyusutannya.
                    Keuntungan atau kerugian dari penjualan aset tetap tersebut
                    dibukukan dalam laporan laba rugi pada tahun yang
                    bersangkutan.
                  </div>
                </div>
              </li>
            </ul>
          </li>

          <li style="margin-bottom: 1%">
            <span style="font-weight: 600; font-size: 17px">Hutang Dagang</span>
            <ul>
              <li style="margin-top: 1%">
                Hutang Dagang dicatat sebesar nilai bruto yang timbul sebagai
                akibat kewajiban perusahaan kepada pihak lain (Supllier) yang
                berhubungan langsung dengan operasional perusahaan.
              </li>
            </ul>
          </li>

          <li style="margin-bottom: 1%">
            <span style="font-weight: 600; font-size: 17px"
              >Hutang Bank (Bag. Lancar)</span
            >
            <ul>
              <li style="margin-top: 1%">
                Hutang Bank (Bag. Lancar) dicatat sebesar nilai bruto yang
                timbul sebagai akibat kewajiban perusahaan kepada pihak Bank
                dalam rentang waktu satu tahun setelah tanggal tutup neraca.
              </li>
            </ul>
          </li>

          <li style="margin-bottom: 1%">
            <span style="font-weight: 600; font-size: 17px">Hutang Pajak</span>
            <ul>
              <li style="margin-top: 1%">
                Hutang Pajak dicatat sebesar nilai bruto yang timbul sebagai
                akibat kewajiban perpajakan perusahaan kepada Direkotorat
                Jendral Pajak.
              </li>
            </ul>
          </li>

          <li style="margin-bottom: 1%">
            <span style="font-weight: 600; font-size: 17px"
              >Pengakuan Pendapatan dan Beban</span
            >
            <ul>
              <li style="margin-top: 1%">
                Pendapatan dari penjualan barang diakui pada saat Perusahaan
                telah secara signifikan memindahkan resiko dan manfaat
                kepemilikan barang kepada pembeli serta tidak terdapat
                ketidakpastian yang signifikan sehubungan dengan arus penerimaan
                pendapatan dari penjualan dan biaya-biaya sehubungan dengan
                penjualan tersebut serta kemungkinan terjadinya
                pengembalianbarang. Pendapatan dan beban diakui dan dicatat atas
                dasar metode accrual basis.
              </li>
            </ul>
          </li>

          <li style="margin-bottom: 1%">
            <span style="font-weight: 600; font-size: 17px"
              >Imbalan Pasca Kerja</span
            >
            <ul>
              <li style="margin-top: 1%">
                Perusahaan sudah mengakui kewajiban imbalan pasca kerja
                sebagaimana diatur dalam SAK ETAP bab 23 "Imbalan Kerja".
                Pengakuan kewajiban tersebut didasarkan pada ketentuan
                Undang-Undang Ketenagakerjaan No. 13/2003. Dalam ketentuan
                tersebut Perusahaan diwajibkan untuk membayarkan imbalan kerja
                kepada karyawannya pada saat mereka berhenti bekerja dalam hal
                mengundurkan diri, pensiun normal, meninggal dunia dan cacat
                tetap. Besarnya imbalan pasca kerja tersebut terutama
                berdasarkan lamanya masa kerja dan kompensasi karyawan pada
                penyelesaian hubungan kerja. Pada dasarnya imbalan kerja
                berdasarkan UU Ketenagakerjaan No. 13/2003 adalah program
                imbalan pasti.
              </li>
            </ul>
          </li>
        </ol>
      </div>
    </b-modal>

    <b-modal
      centered
      size="xl"
      ref="gambaran"
      hide-footer
      title="Gambaran Umum"
    >
      <div style="padding-right: 2%">
        <ol>
          <li style="margin-bottom: 1%">
            <span style="font-weight: 600; font-size: 17px">
              Pendirian Dan Informasi Umum
            </span>
            <ul style="text-align: justify; margin-top: 1%">
              <span
                >{{ client.namaClient }} berkedudukan di
                {{ client.alamatClient }} memiliki identitas serta legalitas
                perusahaan dalam menjalankan proses usahanya sebagai berikut
                :</span
              >
              <li style="margin-top: 1%">
                <b-form-group
                  label-cols="6"
                  label-cols-lg="4"
                  label="Akta Pendirian"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="client.aktaPendirian"
                  ></b-form-input>
                </b-form-group>
              </li>
              <li style="margin-top: 1%">
                <b-form-group
                  label-cols="6"
                  label-cols-lg="4"
                  label="Notaris Akta Pendirian"
                  label-for="input-2"
                >
                  <b-form-input
                    id="input-2"
                    v-model="client.notarisAktaPendirian"
                  ></b-form-input>
                </b-form-group>
              </li>
              <li style="margin-top: 1%">
                <b-form-group
                  label-cols="6"
                  label-cols-lg="4"
                  label="Akta Perubahan Tahun Berjalan"
                  label-for="input-3"
                >
                  <b-form-input
                    id="input-3"
                    v-model="client.aktaPendirianTahunBerjalan"
                  ></b-form-input>
                </b-form-group>
              </li>
              <li style="margin-top: 1%">
                <b-form-group
                  label-cols="6"
                  label-cols-lg="4"
                  label="Notaris Akta Perubahan"
                  label-for="input-4"
                >
                  <b-form-input
                    id="input-4"
                    v-model="client.notarisAktaPerubahan"
                  ></b-form-input>
                </b-form-group>
              </li>
              <li style="margin-top: 1%">
                <b-form-group
                  label-cols="6"
                  label-cols-lg="4"
                  label="NPWP (Nomor Wajib Pajak)"
                  label-for="input-5"
                >
                  <b-form-input
                    id="input-5"
                    v-model="client.NPWP"
                  ></b-form-input>
                </b-form-group>
              </li>
              <li style="margin-top: 1%">
                <b-form-group
                  label-cols="6"
                  label-cols-lg="4"
                  label="PKP (Pengusaha Kena Pajak)"
                  label-for="input-6"
                >
                  <b-form-input
                    id="input-6"
                    v-model="client.PKP"
                  ></b-form-input>
                </b-form-group>
              </li>
              <li style="margin-top: 1%">
                <b-form-group
                  label-cols="6"
                  label-cols-lg="4"
                  label="NIB (Nomor Induk Berusaha)"
                  label-for="input-7"
                >
                  <b-form-input
                    id="input-7"
                    v-model="client.NIB"
                  ></b-form-input>
                </b-form-group>
              </li>
            </ul>
          </li>
          <li style="margin-bottom: 1%">
            <span style="font-weight: 600; font-size: 17px">
              Maksud dan Tujuan
            </span>
            <ul style="margin-top: 1%">
              <div style="display: flex; justify-content: space-between">
                <span
                  >Sesuai dengan akta Pendirian Perusahaan maksud dan tujuan
                  perusahaan adalah sebagai berikut :</span
                >
                <b-button
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #327ff2;
                    border: none;
                    border-radius: 7px;
                  "
                  @click="tambahForm"
                >
                  <img
                    style="height: 24px"
                    src="../../assets/icon/plus-circle.svg"
                    alt=""
                  />
                </b-button>
              </div>

              <li
                style="
                  margin-top: 2%;
                  display: flex;
                  justify-content: space-between;
                "
                v-for="(form, idx) in forms"
                :key="idx"
              >
                <b-form-textarea
                  id="textarea"
                  v-model="forms[idx].maksudDanTujuan"
                  rows="2"
                  max-rows="4"
                  style="width: 95%"
                ></b-form-textarea>
                <b-button
                  v-if="forms.length > 1"
                  style="
                    background-color: #e0544e;
                    border: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                  @click="deleteMaksud(idx)"
                >
                  <img src="../../assets/icon/trash.svg" alt="" />
                </b-button>
              </li>
            </ul>
          </li>
          <li style="margin-bottom: 1%">
            <span style="font-weight: 600; font-size: 17px"> Permodalan </span>
            <ul style="text-align: justify; margin-top: 1%">
              <span
                >Berdasarkan Akta Perubahan terakhir Modal dasar perseroan
                adalah sebagai berikut :</span
              >
              <li style="margin-top: 1%">
                <b-form-group
                  label-cols="6"
                  label-cols-lg="4"
                  label="Modal Saham Tercatat"
                  label-for="input-m1"
                >
                  <b-form-input
                    id="input-m1"
                    v-model="client.modalSahamTercatat"
                  ></b-form-input>
                </b-form-group>
              </li>
              <li style="margin-top: 1%">
                <b-form-group
                  label-cols="6"
                  label-cols-lg="4"
                  label="Jumlah Lembar Saham Tercatat"
                  label-for="input-m2"
                >
                  <b-form-input
                    id="input-m2"
                    v-model="client.jumlahLembarSahamTercatat"
                  ></b-form-input>
                </b-form-group>
              </li>
              <li style="margin-top: 1%">
                <b-form-group
                  label-cols="6"
                  label-cols-lg="4"
                  label="Nilai Nominal per-Lembar Saham"
                  label-for="input-m3"
                >
                  <b-form-input
                    id="input-m3"
                    v-model="client.nominalPerLembarSaham"
                  ></b-form-input>
                </b-form-group>
              </li>
              <li style="margin-top: 1%">
                <b-form-group
                  label-cols="6"
                  label-cols-lg="4"
                  label="Modal Saham Disetor"
                  label-for="input-m4"
                >
                  <b-form-input
                    id="input-m4"
                    v-model="client.modalSahamDisetor"
                  ></b-form-input>
                </b-form-group>
              </li>
              <li style="margin-top: 1%">
                <b-form-group
                  label-cols="6"
                  label-cols-lg="4"
                  label="Jumlah Lembar Saham Disetor"
                  label-for="input-m5"
                >
                  <b-form-input
                    id="input-m5"
                    v-model="client.jmlLembarSahamDisetor"
                  ></b-form-input>
                </b-form-group>
              </li>
            </ul>
          </li>
        </ol>
      </div>
      <div
        class="wrapper"
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 0 2%;
        "
      >
        <div
          class="button-wrapper"
          style="display: flex; width: 95%; justify-content: flex-end"
        >
          <b-button
            @click="ubah()"
            style="
              background-color: #327ff2;
              color: #fff;
              font-family: 'nunito', sans-serif;
              font-weight: 700;
              width: 100%;
              border: none;
              margin-top: 20px;
              height: 40px;
            "
            :disabled="loading"
            >{{ pesan }}</b-button
          >
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "@/ip_config.js";
import WhiteSpinner from "../../components/spinner/white-spinner.vue";
import { mapState } from "vuex";
import Table_client from "../../components/table/table_client.vue";

export default {
  components: {
    WhiteSpinner,
    Table_client,
  },
  computed: {
    ...mapState("apiClient", ["client", "loadingC"]),
  },
  data() {
    let forms = [
      {
        maksudDanTujuan: "",
        clientId: localStorage.getItem("idClient"),
      },
    ];
    return {
      loading: false,
      loading2: false,
      roleLocal: "",
      pesan: "Ubah",
      forms,
    };
  },

  beforeMount() {
    let vm = this;
    this.$store.dispatch("apiClient/getData");
    vm.roleLocal = localStorage.getItem("role");
    this.getMaksud();
  },

  methods: {
    editClient() {
      this.$refs["modal-edit"].show();
    },

    tutupModal1() {
      this.$refs["modal-edit"].hide();
    },

    tambahForm() {
      this.forms.push({
        maksudDanTujuan: "",
        clientId: localStorage.getItem("idClient"),
      });
    },

    getMaksud() {
      axios
        .get("client/readMaksudTujuan/" + localStorage.getItem("idClient"), {
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((res) => {
          if (res.data.message == "sukses" && res.data.data.length > 0) {
            let arr = res.data.data;
            for (let i = 0; i < arr.length; i++) {
              if (!arr[i].clientId) {
                arr[i].clientId = +localStorage.getItem("idClient");
              }
            }
            this.forms = arr;
          }
        });
    },

    deleteMaksud(idDelete) {
      this.forms.splice(idDelete, 1);
    },

    doEdit() {
      let vm = this;
      vm.loading2 = true;

      axios
        .post(
          "client/update/" + localStorage.getItem("idClient"),
          {
            alamatClient: vm.client.alamatClient,
            noHpClient: vm.client.noHpClient,
            emailClient: vm.client.emailClient,
            websiteClient: vm.client.websiteClient,
          },
          {
            headers: { token: localStorage.getItem("token") },
          }
        )
        .then(() => {
          vm.loading2 = false;
          this.$refs["modal-edit"].hide();
          this.$store.dispatch("apiClient/getData");
        });
    },

    ubah() {
      let vm = this;
      vm.loading = true;
      vm.pesan = "Mohon Tunggu";
      let y = localStorage.getItem("idClient");

      axios
        .post(
          "client/update/" + y,
          {
            aktaPendirian: vm.client.aktaPendirian,
            notarisAktaPendirian: vm.client.notarisAktaPendirian,
            aktaPendirianTahunBerjalan: vm.client.aktaPendirianTahunBerjalan,
            notarisAktaPerubahan: vm.client.notarisAktaPerubahan,
            NPWP: vm.client.NPWP,
            PKP: vm.client.PKP,
            NIB: vm.client.NIB,
            modalSahamTercatat: vm.client.modalSahamTercatat,
            jumlahLembarSahamTercatat: vm.client.jumlahLembarSahamTercatat,
            nominalPerLembarSaham: vm.client.nominalPerLembarSaham,
            modalSahamDisetor: vm.client.modalSahamDisetor,
            jmlLembarSahamDisetor: vm.client.jmlLembarSahamDisetor,
          },
          {
            headers: { token: localStorage.getItem("token") },
          }
        )
        .then((res) => {
          if (res.data.message == "sukses") {
            for (let i = 0; i < this.forms.length; i++) {
              if (!this.forms[i].clientId) {
                this.forms[i].clientId = +localStorage.getItem("idCllient");
              }
            }
            axios
              .post(
                "client/updateMaksudTujuan/" + localStorage.getItem("idClient"),
                { bulkMaksudTujuan: this.forms },
                {
                  headers: {
                    token: localStorage.getItem("token"),
                  },
                }
              )
              .then(() => {
                vm.loading = false;
                vm.pesan = "Ubah";
                this.$refs["gambaran"].hide();
              });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");

$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "white": #fafafa,
  "white2": #fff,
  "gray-dark": #b5bac3,
  "blue-light": #e8f3fe,
  "red-light": #ffe8e7,
  "green-light": #eafffa,
  "green": #6adbbe,
);

.wrapper {
  .nama {
    font-size: 28px;
    font-family: "nunito", sans-serif;
    font-weight: 300;
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .button-wrapper2 {
      .btn {
        font-size: 14px;
        background-color: map-get($color, "white2");
        border: 1.5px solid map-get($color, "blue");
        color: map-get($color, "blue");
        font-family: "nunito", sans-serif;
        font-weight: 700;
        height: 28px;
        display: flex;
        align-items: center;
        box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
      }
      .btn:hover {
        font-size: 14px;
        background-color: map-get($color, "blue");
        border: 1.5px solid map-get($color, "blue");
        color: map-get($color, "white");
        font-family: "nunito", sans-serif;
        font-weight: 700;
        height: 28px;
        display: flex;
        align-items: center;
        box-shadow: 0px 10px 35px -10px rgba(15, 93, 210, 0.2);
      }
    }
  }
  .details {
    width: 100%;
    .wrapper-inner {
      // height: 180px;
      display: flex;
      flex-direction: column;
      // justify-content: space-evenly;
      .rows {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1% 0;
        .btn {
          width: 100%;
        }
        .title {
          font-family: "nunito", sans-serif;
          font-weight: 300;
          color: map-get($color, "gray-dark");
          text-transform: capitalize;
        }
        .isi {
          font-family: "nunito", sans-serif;
          font-weight: 300;
          color: map-get($color, "black");
          text-transform: capitalize;
        }
      }
    }
    .rows {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 1% 0;
      .title {
        font-family: "nunito", sans-serif;
        font-weight: 300;
        color: map-get($color, "gray-dark");
        text-transform: capitalize;
        width: 50%;
      }
      .isi {
        font-family: "nunito", sans-serif;
        font-weight: 300;
        color: map-get($color, "black");
        text-transform: capitalize;
        width: 50%;
      }
      .loading {
        width: 60%;
      }
    }
    .rows2 {
      width: 100%;
      margin-top: 5%;
      .btn {
        background-color: #327ff2;
        border: none;
      }
    }
  }
}
</style>
