<template>
  <div class="wrapper">
    <div class="text">
      <div class="title">
        <span
          v-b-tooltip.hover
          :title="client.namaClient"
          v-if="loadingC == false"
          >{{ client.namaClient1 }}</span
        >
        <b-skeleton
          v-else
          class="skeleton-loading"
          animation="throb"
        ></b-skeleton>
      </div>
      <!-- <div class="join">Joined on {{ clientDetails.tanggalkap }}</div> -->
      <div class="peran">peran anda</div>
      <div class="admin" v-if="roleLocal == 'admin'">
        <span v-if="loadingC == false">{{ roleLocal }}</span>
        <b-skeleton
          v-else
          class="skeleton-loading"
          animation="throb"
        ></b-skeleton>
      </div>
      <div class="spv" v-else-if="roleLocal == 'supervisor'">
        <span v-if="loadingC == false">{{ roleLocal }}</span>
        <b-skeleton
          v-else
          class="skeleton-loading"
          animation="throb"
        ></b-skeleton>
      </div>
      <div class="mng" v-else-if="roleLocal == 'manager'">
        <span v-if="loadingC == false">{{ roleLocal }}</span>
        <b-skeleton
          v-else
          class="skeleton-loading"
          animation="throb"
        ></b-skeleton>
      </div>
      <div class="auditor" v-else-if="roleLocal == 'auditor'">
        <span v-if="loadingC == false">{{ roleLocal }}</span>
        <b-skeleton
          v-else
          class="skeleton-loading"
          animation="throb"
        ></b-skeleton>
      </div>
      <div class="jumlah">jumlah client</div>
      <div class="total">
        <span v-if="loadingC == false">{{ project }} project</span>
        <b-skeleton
          v-else
          class="skeleton-loading"
          animation="throb"
        ></b-skeleton>
      </div>

      <div class="buttons">
        <b-button pill @click="openLog">Lihat Log</b-button>
      </div>
    </div>

    <b-modal hide-footer hide-header centered ref="modal-log" size="lg">
      <div class="tutup" style="display: flex; justify-content: flex-end">
        <b-button
          @click="tutupModal1"
          style="background-color: white; border: none"
          ><img
            src="../../assets/icon/close.svg"
            alt=""
            style="width: 15px; height: 15px"
        /></b-button>
      </div>
      <div
        style="
          text-align: center;
          font-weight: 700;
          font-family: 'nunito', sans-serif;
          font-size: 22px;
        "
      >
        Logging
      </div>
      <div v-if="loadingModal == false">
        <div
          class="logs"
          style="
            display: flex;
            align-items: center;
            font-family: 'Nunito', sans-serif;
            text-transform: capitalize;
            padding: 1% 0;
            border-bottom: 1px solid #b5bac3;
          "
          v-for="(log, mcr) in logs"
          :key="mcr"
        >
          <div class="nomor" style="margin-right: 2%">
            <span>{{ mcr + 1 }}.</span>
          </div>

          <div
            class="history"
            style="
              height: 80px;
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;
            "
          >
            <span style="font-weight: 600"
              >Aksi : {{ log.history }}
              <span
                style="text-transform: captalize"
                v-if="log.namaClient != null"
                >- {{ log.namaClient }}</span
              ></span
            >

            <span>
              oleh :
              <span
                style="
                  text-transform: lowercase;
                  color: #327ff2;
                  font-weight: 600;
                  text-decoration: underline;
                "
              >
                {{ log.pengguna }}
              </span>
            </span>

            <span>tanggal : {{ log.createdAt }} WIB</span>
          </div>
        </div>
      </div>
      <div
        v-else
        style="
          height: 350px;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
        "
      >
        <div v-for="sk in 8" :key="sk">
          <b-skeleton animation="throb"></b-skeleton>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "@/ip_config.js";
import whiteSpinner from "../spinner/white-spinner.vue";
import BlueSpinner from "../spinner/blue-spinner.vue";
import { mapState } from "vuex";
import moment from "moment";
moment.locale("id");
export default {
  components: { whiteSpinner, BlueSpinner },

  props: ["currentTab"],

  computed: {
    ...mapState("apiClient", ["client", "project", "loadingC"]),
    ...mapState("apiListProject", ["loadingP"]),
  },
  data() {
    return {
      user: "",
      namaProject: "",
      tahunProject: "",
      id: [],
      options: [],
      jabatan: "",
      loading: false,
      loading2: false,
      loading3: false,
      loadingModal: false,
      logs: [],
      id_client: "",
      roleLocal: "",
    };
  },

  async beforeMount() {
    await this.$store.dispatch("apiListProject/getData");
  },

  mounted() {
    let vm = this;
    vm.roleLocal = localStorage.getItem("role");
  },

  methods: {
    async openLog() {
      let vm = this;
      vm.loadingModal = true;
      let fetch = await axios.get("log/listByClient/" + this.$route.params.id, {
        headers: {
          token: localStorage.getItem("token"),
        },
      });
      fetch.data.forEach((el) => {
        el.createdAt = moment(el.createdAt).format("LLL");
      });
      vm.logs = fetch.data;
      this.$refs["modal-log"].show();
      setTimeout(() => {
        vm.loadingModal = false;
      }, 800);
    },
    tutupModal1() {
      this.$refs["modal-log"].hide();
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");
$color: (
  "blue": #327ff2,
  "black": #061737,
  "red": #e0544e,
  "white": #fafafa,
  "white2": #fff,
  "gray-dark": #b5bac3,
  "blue-light": #e8f3fe,
  "red-light": #ffe8e7,
  "green-light": #eafffa,
  "green": #6adbbe,
  "purple": #5c4af5,
  "purple-light": #f4f3ff,
);
.wrapper {
  display: flex;
  flex-direction: column;
  .text {
    height: 500px;
    .skeleton-loading {
      height: 28px;
    }
    .title {
      font-weight: 700;
      color: map-get($color, "black");
      font-size: 28px;
      font-family: "nunito", sans-serif;
      text-transform: capitalize;
    }
    .join {
      font-weight: 300;
      color: map-get($color, "gray-dark");
      font-family: "nunito", sans-serif;
      font-size: 16px;
      margin-top: 8%;
    }
    .peran {
      font-weight: 300;
      color: map-get($color, "black");
      font-family: "nunito", sans-serif;
      font-size: 16px;
      margin-top: 8%;
      text-transform: capitalize;
    }
    .admin {
      margin-top: 2%;
      span {
        background-color: #eafffa;
        font-family: "nunito", sans-serif;
        border-radius: 50px;
        color: #20bd95;
        text-transform: capitalize;
        border: 2px solid #20bd95;
        padding: 0.5% 5%;
        font-weight: 600;
      }
      .skeleton-loading {
        width: 50%;
        height: 16px;
      }
    }
    .spv {
      margin-top: 2%;
      span {
        background-color: #ffefdb;
        font-family: "nunito", sans-serif;
        padding: 0.5% 5%;
        font-weight: 600;
        border-radius: 50px;
        border: 2px solid #ffac4b;
        color: #ffac4b;
        text-transform: capitalize;
      }
      .skeleton-loading {
        width: 50%;
        height: 16px;
      }
    }
    .mng {
      margin-top: 2%;
      span {
        background-color: #f4f3ff;
        font-family: "nunito", sans-serif;
        padding: 0.5% 5%;
        font-weight: 600;
        border-radius: 50px;
        border: 2px solid #5c4af5;
        color: #5c4af5;
        text-transform: capitalize;
      }
      .skeleton-loading {
        width: 50%;
        height: 16px;
      }
    }
    .auditor {
      margin-top: 2%;
      span {
        background-color: #ffe8e7;
        font-family: "nunito", sans-serif;
        padding: 0.5% 5%;
        font-weight: 600;
        border-radius: 50px;
        border: 2px solid #e0544e;
        color: #e0544e;
        text-transform: capitalize;
      }
      .skeleton-loading {
        width: 50%;
        height: 16px;
      }
    }
    .jumlah {
      margin-top: 8%;
      font-family: "nunito", sans-serif;
      text-transform: capitalize;
      font-weight: 300;
    }
    .total {
      margin-top: 4%;
      span {
        background-color: rgba(238, 238, 238, 0.5);
        font-weight: 700;
        font-family: "nunito", sans-serif;
        text-transform: capitalize;
        color: map-get($color, "black");
        padding: 2% 6%;
        border-radius: 50px;
      }
      .skeleton-loading {
        width: 50%;
        height: 16px;
      }
    }
  }
  .buttons {
    margin-top: 8%;
    .btn {
      font-weight: 500;
      background-color: map-get($color, "blue");
      border: none;
      font-size: 14px;
    }
  }
}
</style>
